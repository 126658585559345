import logo from './webtruss.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          WebTruss.
        </h1>
        <p>This project is a collection of common wrappers and code snippets.</p>
        <p>This project is under construction.</p>
        <a href='https://github.com/Salman-Sali/WebTruss'>
          Feel free to contribute.
        </a>
      </header>
    </div>
  );
}

export default App;
